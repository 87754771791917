<template>
  <!-- 活动面经主页 -->
  <div>
    <div class="plan-banner">
      <div class="banner-title">
        <div class="main-title">面经打卡计划</div>
        <div>
          <div class="sub-title1">发面经，写面经</div>
          <div class="sub-title2">「LeetCode 1-400题讲解」免费学</div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="page-title">
        “面经打卡计划”有奖征集，让世界上没有难面的公司
      </div>
      <div class="create-time">2021.09.13 12:12:00</div>
      <div class="section-title">【征集内容】</div>
      <div class="section-content">
        <p>
          「任务一」发布的面经需为原创内容、真实面试经历。面经需保证内容完整性，不得侵犯任何第三方的知识产权和其他任何权利（包括但不限于商标权、版权、肖像权），面经内容至少包含2道面经题，面经类型不限，经CSON官方审核通过，即可获得奖励。一旦发现抄袭，立即取消奖励资格；
        </p>
        <p>
          「任务二」自参与活动之日起，5日内，需在个人打卡页面，发表3条个人动态，与大家分享个人学习经验。
        </p>
      </div>
      <div class="section-title">【奖励内容】</div>
      <div class="section-content">
        <p>
          1、新人注册 - 奖励
          <span style="color: rgba(250, 100, 0, 1)"
            >「LeetCode 1-400题讲解」</span
          >课，<span style="color: rgba(250, 100, 0, 1)">15天</span>免费学习奖励
        </p>
        <p>
          2、发布面经 - 奖励
          <span style="color: rgba(250, 100, 0, 1)"
            >「LeetCode 1-400题讲解」</span
          >课，<span style="color: rgba(250, 100, 0, 1)">3天</span
          >免费学习奖励&lt;可累计&gt;
        </p>
        <p>
          3、回答面经题 - 奖励
          <span style="color: rgba(250, 100, 0, 1)"
            >「LeetCode 1-400题讲解」</span
          >课，<span style="color: rgba(250, 100, 0, 1)">3天</span
          >免费学习奖励&lt;可累计&gt;
        </p>
      </div>
      <div class="section-title">【活动规则】</div>
      <div class="section-content">
        <p>
          1、新人用户自参与活动并领取新人奖励之日起5日内，需完成「征集任务一、二」，提交2道面经题，且在个人打卡页面累计发表3条个人动态，超出5日未完成，则取消「LeetCode
          1-400题讲解」课，15天免费学奖励；
        </p>
        <p>
          2、参与活动期间，用户通过发布面经与回答面经所获奖励可累计计算，且与规则1内容互不干预。
        </p>
        <p>
          3、所有内容审核通过后，均由系统自动发放至用户CSON官方个人账号。用户可登录CSON个人中心
          - 我的课程查阅
        </p>
        <p style="color: rgba(255, 59, 48, 1)">
          本次活动的最终解释权归CSON官方所有。
        </p>
      </div>

      <div class="interviewButton">
        <div class="ibContainer" @click="goInterview">分享面经</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "interviewPlan",
  methods: {
    goInterview() {
      this.$router.push("/interviewExperience");
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
<style scoped>
.plan-banner {
  position: relative;
  background: url(../../assets/company/interviewPlan.png) no-repeat center top;
  height: 300px;
}
.plan-banner img {
  width: 100%;
  vertical-align: top;
}
.banner-title {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translateX(-50%) translateY(-50%);
}
.main-title {
  font-size: 28px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #ffffff;
  line-height: 28px;
  letter-spacing: 2px;
  margin-bottom: 30px;
}
.sub-title1,
.sub-title2 {
  font-size: 18px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #ffffff;
  line-height: 18px;
  margin-bottom: 10px;
}
.container {
  width: 1200px;
  margin: 0 auto;
  padding: 50px 0;
}
.page-title {
  font-size: 24px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #34495e;
  line-height: 24px;
}
.create-time {
  font-size: 12px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: rgba(52, 73, 94, 0.7);
  line-height: 18px;
  margin-top: 16px;
}
.section-title {
  font-size: 20px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #fa6400;
  line-height: 30px;
  margin-top: 50px;
  margin-bottom: 30px;
  text-decoration: underline;
  text-decoration-color: rgba(250, 100, 0, 0.2);
}
.section-content p {
  font-size: 14px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: rgba(52, 73, 94, 0.7);
  line-height: 18px;
  margin-bottom: 16px;
  line-height: 24px;
}
.interviewButton {
  text-align: center;
  margin-top: 100px;
}
.ibContainer {
  background-image: linear-gradient(to right, #fd1d1d, #fcb045);
  line-height: 44px;
  width: 300px;
  margin: 0 auto;
  font-size: 16px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
  border-radius: 4px;
}
</style>